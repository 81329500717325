import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/landing';

import FeatureBenefit from 'components/content-blocks/customer-feature-benefit';
import FeaturesGrid from 'components/content-blocks/features-grid';
import HighlightCards from 'components/content-blocks/highlight';
import MediaObject from 'components/content-blocks/media-object';
import ProductVideo from 'components/content-blocks/product-video';
import TemplateVsWiw from 'components/content-blocks/template-vs-wiw';
import Yoast from 'components/globals/yoast';

import './styles/schedule-templates.scss';

export default function ScheduleTemplates() {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "landing-schedule-templates" } }) {
        edges {
          node {
            wordpress_id
        yoast_head_json {
          title
          description
          canonical
          og_title
          og_description
          og_image {
            url {
              source_url
            }
          }
          og_url
          og_type
          twitter_title
          twitter_description
          twitter_image {
            source_url
          }
        }
            acf {
              video_block_1 {
                video_id
                image_desktop {
                  source_url
                }
                image_mobile {
                  source_url
                }
              }
              features_grid {
                title
                sub_title
                buttons {
                  title
                  body
                  alt_text
                  icon_width
                  icon
                  mobile_screenshot {
                    source_url
                  }
                  desktop_screenshot {
                    source_url
                  }
                }
              }
              customer_feature_benefit {
                sub_title
                title
                copy
                customer {
                  business
                  name
                  photo_desktop {
                    source_url
                  }
                  photo_mobile {
                    source_url
                  }
                  since
                }
                stats {
                  value_1
                  descriptor_1
                  value_2
                  descriptor_2
                  value_3
                  descriptor_3
                }
              }
              media_object {
                body
                sub_title
                title
                desktop_image {
                  source_url
                }
                mobile_image {
                  source_url
                }
                button_url
                button_text
              }
              template_vs_wiw {
                Wiw {
                  body
                  button {
                    title
                  }
                  desktop_image {
                    source_url
                  }
                  mobile_image {
                    source_url
                  }
                  title
                }
                template {
                  body
                  button {
                    title
                  }
                  desktop_image {
                    source_url
                  }
                  mobile_image {
                    source_url
                  }
                  title
                }
              }
              cards_1 {
                header {
                  title
                  sub_title
                  copy
                }
                card {
                  header
                  image {
                    source_url
                  }
                  title
                  copy
                  link_url
                  link_text
                }
              }
            }
          }
        }
      }
    }
  `);

  const pageContent = data.allWordpressPage.edges[0].node.acf;
  const yoast = data.allWordpressPage.edges[0].node.yoast_head_json;
  const highlightCards = pageContent.cards_1;
  const productVideo = pageContent.video_block_1;
  const featuresGrid = pageContent.features_grid;
  const customerFeatureBenefit = pageContent.customer_feature_benefit;
  const mediaObject = pageContent.media_object;
  const templateVsWiw = pageContent.template_vs_wiw;

  return (
    <Layout>
      <Yoast { ...yoast } />
      <div className="lp-schedule-templates-page-container" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <TemplateVsWiw { ...templateVsWiw[0] } />
        <HighlightCards { ...highlightCards } hasTitle hasImage />
        <FeatureBenefit { ...customerFeatureBenefit[0] } />
        <FeaturesGrid { ...featuresGrid[0] } />
        <MediaObject { ...mediaObject[0] } />
        <ProductVideo id="video" { ...productVideo } />
      </div>
    </Layout>
  );
}
